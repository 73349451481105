/* eslint-disable react/no-danger, react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '@/components/Seo'
import Blocks from '@/utils/Blocks'
import { cleanProps } from '@/utils'
import useConstant from '@/hooks/useConstant'
/* eslint-disable no-unused-vars */
import { pageQuery, caseStudyQuery } from '@/queries'
/* eslint-enable no-unused-vars */
import * as T from '@/types'

function CaseStudyIndex({
	pageContext: { media, wordpress_id },
	data: {
		entries: { nodes },
		page: { blocks, yoast, featured_media }
	}
}) {
	const data = useConstant(
		() =>
			cleanProps(blocks, media).map(item => {
				if (item.type === 'acf/casestudy-collection') {
					return {
						...item,
						entries: nodes.map(({ blocks: csBlock, ...node }) => {
							const img = cleanProps(csBlock, media).find(
								block => block.type === 'acf/case-study-hero'
							)
							return {
								...node,
								image: img && img.background_image
							}
						})
					}
				}

				return item
			}),
		[blocks, media]
	)

	return (
		<>
			<SEO {...yoast} image={featured_media} />
			<Blocks data={data} media={media} currentPage={wordpress_id} />
		</>
	)
}

CaseStudyIndex.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired,
		entries: T.caseStudies.isRequired
	})
}

export const query = graphql`
	query CaseStudyIndexQuery($path: String!) {
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}
		entries: allWordpressWpCaseStudy(filter: { slug: { ne: "template" } }) {
			nodes {
				...caseStudyQuery
			}
		}
	}
`

export default CaseStudyIndex
